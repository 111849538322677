<script setup lang="ts">
import type { SeoBreadcrumb } from '@/types/cms';

defineOptions({
  name: 'NewsPage',
});

const { $mopI18n, $storyblokLivePreview } = useNuxtApp();
const route = useRoute();
const classNameRef = ref(['']);
const { getCmsStory, cmsStoryModelRef, loadingRef } = useMopCms();
const { initTransition } = useMopPageTransitionClient();
const { getBreadcrumbSchema, handleHeadForCmsPage } = useMopSeo();

initTransition(classNameRef, loadingRef);

onMounted(async () => {
  await getCmsStory('/news');
});

const cmsContentState = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const title = cmsStoryModelRef.value.getAttribute('title');
  const subTitle = cmsStoryModelRef.value.getAttribute('subTitle');

  return {
    title,
    subTitle,
  };
});

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

const seoHeadRef = computed(() => {
  if (!cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.news.name'),
      url: route.path,
    },
  ];

  return {
    ...handleHeadForCmsPage(cmsStoryModelRef.value),
    ...getBreadcrumbSchema(breadcrumbs),
  };
});

useHead(seoHeadRef);
</script>

<template>
  <div :class="['news-page', 'transition', ...classNameRef]">
    <MopPageLoad :class="classNameRef" />
    <div class="transition-content">
      <h1 class="news-page__headline">
        {{ cmsContentState.title }}
      </h1>
      <h2 class="news-page__sub-headline">
        {{ cmsContentState.subTitle }}
      </h2>
      <div class="news-page__categories">
        <NuxtLink
          :to="$mopI18n.localePath($urls.NEWS_PAGE)"
          :class="[
            'news-page__category-item',
            {
              'news-page__category-item--active': !$route.params.category,
            },
          ]"
        >
          {{ $mopI18n.t('locale.all') }}
        </NuxtLink>
        <NuxtLink
          v-for="category in $mopConfig.getAvailableNewsCategories()"
          :key="category"
          :to="
            category === $route.params.category
              ? $mopI18n.localePath(`${$urls.NEWS_PAGE}`)
              : $mopI18n.localePath(`${$urls.NEWS_PAGE}/${category}`)
          "
          no-prefetch
          :class="[
            'news-page__category-item',
            {
              'news-page__category-item--active': category === $route.params.category,
            },
          ]"
        >
          {{
            $mopI18n.te(`locale.news.categories.${category}`)
              ? $mopI18n.t(`locale.news.categories.${category}`)
              : category
          }}
        </NuxtLink>
      </div>

      <div class="news-page__items">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news-page {
  padding: $space30 $global-padding $space10 $global-padding;
}

.news-page__categories {
  display: flex;
  margin: 0 0 $space20 0;
  padding: 0;
  list-style: none;
  width: 100%;
  overflow-x: auto;
}

.news-page__category-item {
  @include text-style(strong);

  margin-right: $space20;
  text-transform: uppercase;
  opacity: $disabled-opacity;
  white-space: nowrap;
}

.news-page__category-item--active {
  opacity: 1;
}

.news-page__headline {
  @include text-style(headline, 0);

  margin: 0;
  text-transform: uppercase;
}

.news-page__sub-headline {
  @include text-style(strong);

  margin: 0 0 $space40;
  text-transform: uppercase;
}
</style>
